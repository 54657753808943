<template>
  <div v-loading="loading" class="full-service-properties">
    <el-row>
      <h1 class="header">
        Full Service Properties
      </h1>
    </el-row>
    <el-row>
      <data-table
        :data="properties"
        :default-sort="{ prop: 'company_name' }"
        class="service-properties-table"
        @row-click="handleRowClick"
      >
        <el-table-column
          label="Company Name"
          prop="company_name"
          sortable
        />
        <el-table-column
          label="Property Name"
          prop="property_name"
          sortable
        />
        <el-table-column
          label="Property Status"
          prop="property_status"
          sortable
          width="145"
        >
          <template slot-scope="scope">
            <el-tag
              :type="getStatusTagType(scope.row.property_status)"
              size="medium"
              disable-transitions
            >
              {{ scope.row.property_status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="Poster"
          prop="poster.name"
          sortable
        />
        <el-table-column
          label="Posting Day"
          prop="posting_day"
          sortable
        />
        <el-table-column
          label="Last Posting Completed"
          prop="last_post_date"
          sortable
        />
      </data-table>
    </el-row>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import RooofAPI from '@/services/api/rooof'

import { getConstantLabelByValue } from '@/utils/constants'
import { getStatusTagType } from '@/utils/rooof'
import { format, parseISO } from 'date-fns'

export default {
  name: 'FullServiceProperties',
  components: {
    'data-table': DataTable
  },
  data () {
    return {
      loading: false,
      properties: []
    }
  },
  created () {
    this.getStatusTagType = getStatusTagType
    this.fetchFullServiceProperties()
  },
  methods: {
    /**
     * Fetches all properties with a non-inactive full service subscription
     */
    async fetchFullServiceProperties () {
      try {
        this.loading = true
        const properties = await RooofAPI.properties.list({ is_caas: true })

        const companies = await RooofAPI.companies.agency()
        const posterMap = {}
        for (const company of companies) {
          posterMap[company.id] = company.assigned_to
        }

        this.properties = properties.map(property => {
          return {
            property_id: property.id,
            property_name: property.name,
            property_status: property.status,
            poster: posterMap[property.company],
            posting_day: getConstantLabelByValue('postingDayOptions', property.posting_day),
            last_post_date: property.posting_completed[0]
              ? format(parseISO(property.posting_completed[0]), 'MMM d, yyyy @ h:mm a') : '',
            company_name: this.$store.getters.getCompanyById(property.company).human_name
          }
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Handles when a row in the data table is clicked.
     * Navigates to the property's full service posts page.
     *
     * @param {Object} row
     */
    handleRowClick (row) {
      this.$router.push({ name: 'FullServicePosts', params: { id: row.property_id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.full-service-properties {
  .header {
    margin: 0.5em 0;
    font-size: 1.5rem;
  }
  .service-properties-table {
    ::v-deep .el-table__row:hover {
      cursor: pointer;
    }
  }
}
</style>
